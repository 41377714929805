import moment from "moment";
import React from "react";
import ColumnFlex from "./ColumnFlex";
import Formatter from "./Formatter";

const Label = ({ discription, title }) => (
    <p className="font-bahij-bold text-gray-800  text-tow p-1">
        {title} :<span className=" text-red-400 font-bahij-bold">{discription}</span>
    </p>
);

const TransfersCard = ({ item }) => (
    <ColumnFlex key={item.id} className="shadow-xl bg-gray-50 my-2 rounded-2xl px-4 py-2">
        <Label title="cName" discription={item.cName} />
        <Label title="c" discription={item.c} />
        <Label title="d" discription={moment(item.d).format("YYYY/MM/DD")} />
        <Label title="cu" discription={item.cu} />
        <Label title="a" discription={Formatter(item.a)} />
        <Label title="ra" discription={item.ra} />
        <Label title="r" discription={item.r} />
        <Label title="s" discription={item.s} />
        <Label title="ds" discription={item.ds} />
        <Label title="sc" discription={item.sc} />
    </ColumnFlex>
);

export default TransfersCard;
